import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import bg15 from '../assets/bg155.jpg';
import bg16 from '../assets/bgtree2.jpg';
import inst1 from '../assets/inst1.jpg'
import inst2 from '../assets/inst2.jpg'
import inst3 from '../assets/inst3.jpg'
import inst4 from '../assets/inst4.jpg'
import inst5 from '../assets/inst5.jpg'
import inst6 from '../assets/inst6.jpg'
import inst21 from '../assets/inst21.jpg'
import inst22 from '../assets/inst22.jpg'
import inst23 from '../assets/inst23.jpg'
import inst24 from '../assets/inst24.jpg'
import inst31 from '../assets/inst31.jpg'
import inst32 from '../assets/inst32.jpg'
import inst33 from '../assets/inst33.jpg'
import inst34 from '../assets/inst34.jpg'
import inst35 from '../assets/inst35.jpg'
import inst36 from '../assets/inst36.jpeg'
import inst37 from '../assets/inst37.jpeg'
import inst38 from '../assets/inst38.jpeg'
import inst39 from '../assets/inst39.jpeg'
import inst40 from '../assets/inst40.jpeg'
import inst41 from '../assets/inst41.jpeg'
import imgg1 from '../assets/imgg1.jpeg'
import ww1 from '../assets/ww1.jpeg'
import ww2 from '../assets/ww2.jpeg'
import ww3 from '../assets/ww3.jpeg'
import ww4 from '../assets/ww4.jpeg'
import ww5 from '../assets/ww5.jpeg'
import ww6 from '../assets/ww6.jpeg'
import ww7 from '../assets/ww7.jpeg'
import ww8 from '../assets/ww8.jpg'
import ww9 from '../assets/ww9.jpeg'
import ww12 from '../assets/ww12.jpg'
import { FaInstagram, FaPhone } from 'react-icons/fa';
import { LocationMarkerIcon } from '@heroicons/react/outline';

// Define the data for each tab
const data = {
  "Make-Up Artists": [
    { name: "Norhan Amr", instagram: "@by_norhanamr", img:inst1 },
    { name: "Shams ElSergany", instagram: "@shams.elsergany_makeupartist", img:inst2 },
    { name: "Mirna Gamal", instagram: "@mirnagamal.makeupartist", img:inst3 },
    { name: "Sara Erian", instagram: "@bysaraerian", img:inst4 },
    { name: "Norhan Kamal", instagram: "@norhankamal_makeup_artist", img:inst5, phone: "+201114665660" },
    { name: "Dina Dimitry", instagram: "@dina.dimitry", img:inst6, phone: "+201006142525 (Assistant’s number, call her to make resv.)" }
  ],
  "Hair Stylists": [
    { name: "Norhan Kamal", instagram: "@norhankamal_makeup_artist", img:inst5, phone: "+201114665660" },
    { name: "Al Sagheer Salons", instagram: "@alsagheersalons", img:inst21, phone:"+201063975522", location:'https://maps.app.goo.gl/ihZWGpgCxiaP5hKf7' },
    { name: "Toi Beauty Salon", instagram: "@toibeautysalon", img:inst22, phone:"+201004440044", location:'https://maps.app.goo.gl/xPCam8rpGfpsz6oa9' },
    { name: "Á La Mode Salon", instagram: "@alamodesalons", img:inst23, phone:"+201200337578", location:'https://maps.app.goo.gl/Fxxzbpge9hQEjgoi6' },
    { name: "Alfred & Mina", instagram: "@alfredandmina", img:inst24, phone:"+201020777779", location:'https://maps.app.goo.gl/AnnQC4Ljto5F9yfH7' },
    { name: "Chez Richard Beauty Salon ", img:ww12, phone:"+201270433337", location:'https://maps.app.goo.gl/kEVESYonhaj5AuYB8' }
    
  ],
  "Restaurants": [
    { name: "Crimson Bar and Grill", instagram: "@crimsoncairo", img:inst31, phone:"+201275055555", location:'https://maps.app.goo.gl/Gjp7GJq1rCP26zySA' },
    { name: "Izakaya Cairo", instagram: "@izakayacairo", img:inst32, phone:"+201271116223", location:'https://maps.app.goo.gl/rmHbk1AFVAG22a1j6' },
    { name: "The Lemon Tree & Co.", instagram: "@thelemontreeandco", img:inst33, phone:"+201120999290", location:'https://maps.app.goo.gl/1LASTdKuqC9EgLq2A' },
    { name: "SASS Dance and Night Club", instagram: "@sassegypt", img:inst34, phone:"+201279966135", location:'https://maps.app.goo.gl/PzWNRg1MZpodBsu68' },
    { name: "Sachi Restaurants", instagram: "@sachirestaurants", img:inst35, phone:"+201280902028", location:'https://maps.app.goo.gl/RjZZ1bUbm3BzhfZR7' },
    { name: "Pier88 Group", instagram: "@pier88group", img:inst36, phone:"+201080888876", location:'https://maps.app.goo.gl/Gi2Q6UWn2YBaQSTD6' },
    { name: "Khufu’s Restaurant", instagram: "@khufusrestaurant", img:inst37, phone:"+201080058888", location:'https://maps.app.goo.gl/hJNC6yDyc5ULUkPN8' },
    { name: "9 Pyramids Lounge", instagram: "@9pyramidslounge", img:inst38, phone:"+201212299999", location:'https://maps.app.goo.gl/z4TyxyW9J5i9Bwnq8' },
    { name: "Kazoku Restaurant", instagram: "@kazokuegypt", img:inst39, phone:"+201270004877", location:'https://maps.app.goo.gl/LRrGXp2x5vqX5zXf9' },
    { name: "Galambo Seafood Restaurant", instagram: "@galamboeg", img:inst40, phone:"+201212223330", location:'https://maps.app.goo.gl/xnoyKt6cUBMeL2mg9' },
    { name: "Em Sherif Cafe", instagram: "@emsherifcafe_eg", img:inst41, phone:"+201222355439", location:'https://maps.app.goo.gl/HEMcHwSFP2xyFsnh7' }
  ],
  "Places To Go":[
    {name:"Waterway 1", location:"https://maps.app.goo.gl/1tTRDWmML32moPxX6",img:ww1},
    {name:"Waterway 2", location:"https://maps.app.goo.gl/dji9Yz8UsFTsUEVR9",img:ww2},
    {name:"Garden 8", location:"https://maps.app.goo.gl/hdMyyHdtHdXD5HZFA",img:ww3},
    {name:"Capital business park", location:"https://maps.app.goo.gl/jh3ns5enwQGpVKVq5",img:ww4},
    {name:"Park St", location:"https://maps.app.goo.gl/6w7SVBNcCYwqgEkF9",img:ww5},
    {name:"Arkan", location:"https://maps.app.goo.gl/zDo1H9NaAtGX8QHo8",img:ww6},
    {name:"5a", location:"https://maps.app.goo.gl/toDvZ5NgH4aK2xb36",img:ww7},
  ],
  "Boutiques":[
    {name:"Villa Baboushka", location:"https://maps.app.goo.gl/4Vprfn9gv7qXfSqX7",img:ww8},
    {name:"Maison 69", location:"https://maps.app.goo.gl/Bs6F6w1PJTiB3GKQ8",img:ww9},
  ],
  "Car Rental & Airport Pickup ":[
    
    { "name": "Car Rental & Airport Pickup (View Travel)", phone: "+201112777517" }
  ]
};

const dataArabic = {
  "فنانين الماكياج": [
    { name: "نورهان عمرو", instagram: "@by_norhanamr", img: inst1 },
    { name: "شمس السرجياني", instagram: "@shams.elsergany_makeupartist", img: inst2 },
    { name: "ميرنا جمال", instagram: "@mirnagamal.makeupartist", img: inst3 },
    { name: "سارة إريان", instagram: "@bysaraerian", img: inst4 },
    { name: "نورهان كمال", instagram: "@norhankamal_makeup_artist", img: inst5, phone: "+20 111 466 5660" },
    { name: "دينا ديميتري", instagram: "@dina.dimitry", img: inst6, phone: "+20 100 614 2525 (رقم المساعده، اتصل بها للحجز)" }
  ],
  "مصففي الشعر": [
    { name: "صالونات الصغير", instagram: "@alsagheersalons", img: inst21, phone: "+20 106 397 5522", location:'https://maps.app.goo.gl/ihZWGpgCxiaP5hKf7' },
    { name: "نورهان كمال", instagram: "@norhankamal_makeup_artist", img: inst5, phone: "+20 111 466 5660" },
    { name: "صالون تجميل توي", instagram: "@toibeautysalon", img: inst22, phone: "+20 100 444 0044", location:'https://maps.app.goo.gl/xPCam8rpGfpsz6oa9' },
    { name: "صالونات آ لا مود", instagram: "@alamodesalons", img: inst23, phone: "+20 120 033 7578", location:'https://maps.app.goo.gl/Fxxzbpge9hQEjgoi6' },
    { name: "ألفريد ومينا", instagram: "@alfredandmina", img: inst24, phone: "+20 102 077 7779", location:'https://maps.app.goo.gl/AnnQC4Ljto5F9yfH7' },
    { name: "صالون ريتشارد و رزق", img:ww12, phone:"+201270433337", location:'https://maps.app.goo.gl/kEVESYonhaj5AuYB8' }
  ],
  "المطاعم": [
    { name: "كريمسون بار اند جريل", instagram: "@crimsoncairo", img: inst31, phone: "+20 127 505 5555", location:'https://maps.app.goo.gl/Gjp7GJq1rCP26zySA' },
    { name: "إيزاكيا القاهرة", instagram: "@izakayacairo", img: inst32, phone: "+20 127 111 6223", location:'https://maps.app.goo.gl/rmHbk1AFVAG22a1j6' },
    { name: "ذا ليمون تري اند كو", instagram: "@thelemontreeandco", img: inst33, phone: "+20 112 099 9290", location:'https://maps.app.goo.gl/1LASTdKuqC9EgLq2A' },
    { name: "ساس دانس ونادي ليلي", instagram: "@sassegypt", img: inst34, phone: "+20 127 996 6135", location:'https://maps.app.goo.gl/PzWNRg1MZpodBsu68' },
    { name: "مطاعم ساشي", instagram: "@sachirestaurants", img: inst35, phone: "+20 128 090 2028", location:'https://maps.app.goo.gl/RjZZ1bUbm3BzhfZR7' },
    { name: "مجموعة بيير 88", instagram: "@pier88group", img: inst36, phone: "+20 108 088 8876", location:'https://maps.app.goo.gl/Gi2Q6UWn2YBaQSTD6' },
    { name: "مطعم خوفو", instagram: "@khufusrestaurant", img: inst37, phone: "+20 108 005 8888", location:'https://maps.app.goo.gl/hJNC6yDyc5ULUkPN8' },
    { name: "9 بيراميدز لاونج", instagram: "@9pyramidslounge", img: inst38, phone: "+20 121 229 9999", location:'https://maps.app.goo.gl/z4TyxyW9J5i9Bwnq8' },
    { name: "مطعم كازوكو", instagram: "@kazokuegypt", img: inst39, phone: "+20 127 000 4877", location:'https://maps.app.goo.gl/LRrGXp2x5vqX5zXf9' },
    { name: "مطعم غالامبو للمأكولات البحرية", instagram: "@galamboeg", img: inst40, phone: "+20 121 222 3330", location:'https://maps.app.goo.gl/xnoyKt6cUBMeL2mg9' },
    { name: "مقهى إم شريف", instagram: "@emsherifcafe_eg", img: inst41, phone: "+20 122 235 5439", location:'https://maps.app.goo.gl/HEMcHwSFP2xyFsnh7' }
  ],
  " اماكن للزياره": [
  { "name": "الوايترويه 1", "location": "https://maps.app.goo.gl/1tTRDWmML32moPxX6" ,img:ww1},
  { "name": "الوايترويه 2", "location": "https://maps.app.goo.gl/dji9Yz8UsFTsUEVR9" ,img:ww2},
  { "name": "جاردن 8", "location": "https://maps.app.goo.gl/hdMyyHdtHdXD5HZFA" ,img:ww3},
  { "name": "كابيتال بيزنس بارك", "location": "https://maps.app.goo.gl/jh3ns5enwQGpVKVq5" ,img:ww4},
  { "name": "بارك ستريت", "location": "https://maps.app.goo.gl/6w7SVBNcCYwqgEkF9" ,img:ww5},
  { "name": "أركان", "location": "https://maps.app.goo.gl/zDo1H9NaAtGX8QHo8" ,img:ww6},
  { "name": "فايف ايه", "location": "https://maps.app.goo.gl/toDvZ5NgH4aK2xb36" ,img:ww7}
  ],
  "بوتيكات": [
    { "name": "فيلا بابوشكا", "location": "https://maps.app.goo.gl/4Vprfn9gv7qXfSqX7" ,img:ww8},
    { "name": "ميزون 69", "location": "https://maps.app.goo.gl/Bs6F6w1PJTiB3GKQ8" ,img:ww9}
  ],
  "استقبال الضيوف و السيارات":[
    
    { "name": "خدمة ايجار السيارات و الاستقبال بالمطار (فيو ترافيل)", phone: "+201112777517" }
  ]


};




const TwoSideSection = ({ isArabic }) => {
  const [activeTab, setActiveTab] = useState(isArabic ? 'فنانين الماكياج' : 'Make-Up Artists');
  
  // Use refs to animate the tab and content section
  const contentRef = useRef();

  // Animate tab content when activeTab changes
  useEffect(() => {
    // Fade out the current content
    gsap.fromTo(contentRef.current, { opacity: 0 }, { opacity: 1, duration: 2 });
  }, [activeTab]);

  const selectedData = isArabic ? dataArabic : data;
  const tabs = Object.keys(selectedData);

  return (
    <div className='w-full h-ful min-h-[80vh] grid grid-cols-1 md:grid-cols-3 bg-white z-10 relative'>
      
      {/* Left Section with Tabs */}
      <div className='w-full h-ful flex flex-col items-center' style={{
        backgroundImage: `url(${bg15})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      
        <div className="text-white text-center mt-4 flex flex-col justify-evenly items-center h-full min-h-[40vh]">
          {tabs.map((tab) => (
            <div
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`cursor-pointer py-2 ${activeTab === tab ? 'font-bold underline' : ''}`}>
              {tab}
            </div>
          ))}
          <div
            
            onClick={() => setActiveTab('accommodation')}
            className={`cursor-pointer py-2 ${activeTab === 'accommodation' ? 'font-bold underline' : ''}`}>
            {isArabic?'الفنادق':'Accommodation'}
          </div>
        </div>
      </div>

      {/* Right Section showing dynamic content */}
      <div ref={contentRef} className='w-full h-ful overflow-visible min-h-[80vh] col-span-2 flex flex-col justify-start p-8' style={{
        backgroundImage: `url(${bg16})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <h2 className={`text-primary ${isArabic&&'text-end'} text-lg font-bold mb-4 md:hidden`}>{activeTab==='accommodation'?"":activeTab}</h2>
        <div className={`text-primary ${isArabic&&'text-end'} grid grid-cols-2 sm:grid-cols-3 gap-4`}>
        

      {selectedData[activeTab]?.map((item, index) => (
        <div key={index} className={`mb-4 shadow-lg rounded-lg border border-gray-200 bg-white bg-opacity-70 flex flex-col justify-between ${selectedData[activeTab].length<2&&'col-span-3'}`}>
          {/* Card Content */}
          <div className="p-4 flex flex-col md:flex-row items-center text-center md:text-start md:items-start">
            {/* Image Avatar */}
            {item.img&&<img
              src={item.img} // Assuming you have an image URL in the item data
              alt={`${item.name}'s avatar`}
              className="w-16 h-16 rounded-full mr-4"
            />}

            {/* Content */}
            <div>
              <strong className="text-lg">{item.name}</strong>
            </div>
          </div>

          {/* Card Footer */}
          <div className="border-t flex justify-evenly items-end  p-2">
            {/* Instagram link if exists */}
            {item.instagram && (
              <a
                href={`https://instagram.com/${item.instagram.replace('@', '')}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 flex items-center text-sm"
              >
                <FaInstagram className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                {/* {item.instagram} */}
              </a>
            )}

            {/* Phone number if exists */}
            {item.phone && item.instagram  && (
              
              <div className='border-l border-gray-300 h-full'></div>
            )}
            {/* Phone number if exists */}
            {item.phone && (
              
              <a
                href={`tel:${item.phone}`}
                className="text-blue-500 flex items-center text-sm "
              >
                <FaPhone className="w-3 h-3  md:w-5 md:h-5 mr-2" />
                {/* {item.phone} */}
              </a>
            )}
            {item.location && item.instagram && (
              
              <div className='border-l border-gray-300 h-full'></div>
            )}
            {item.location && (
              
              <a
                href={item.location}
                target="_blank" rel="noopener noreferrer"
                className="text-blue-500 flex items-center text-sm "
              >
                <LocationMarkerIcon className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                {/* {item.phone} */}
              </a>
            )}

            {/* Add more sections if needed */}
          </div>
        </div>
      ))}


        </div>
        
        {activeTab==="accommodation"&&<div  className="mb-4 shadow-lg rounded-lg bg-white bg-opacity-70 border border-gray-200 flex flex-col justify-between">
          {/* Card Content */}
          <div className="p-4 flex flex-col md:flex-row items-center text-center md:text-start  md:items-start">
            {/* Image Avatar */}
            <img
              src={imgg1} // Assuming you have an image URL in the item data
              alt={`avatar`}
              className="w-16 h-16 rounded-full mr-4"
            />

            {/* Content */}
            <div>
              <strong className="text-lg">{isArabic ? 'حجز فندق سانت ريجيس' : 'Saint Regis Hotel Reservation'}</strong>
            </div>
          </div>

          {/* Card Footer */}
          <div className="border-t flex justify-evenly items-end  p-2">
            {/* Instagram link if exists */}
            
              <a
                href={`https://www.marriott.com/event-reservations/reservation-link.mi?id=1726157926751&key=GRP&guestreslink2=true`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 flex items-center text-sm px-4"
              >
                {isArabic?'حجز':'Online Reservation'}
                {/* {item.instagram} */}
              </a>
            

            {/* Phone number if exists */}
            
              
              <div className='border-l border-gray-300 h-full'></div>
            
            {/* Phone number if exists */}
            
              
              <a
                href={`tel:+201225327123`}
                className="text-blue-500 flex items-center text-sm  px-4"
              >
                <FaPhone className="w-5 h-5 mr-2" />
                {/* {item.phone} */}
              </a>
            

            {/* Add more sections if needed */}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default TwoSideSection;
