import { useState, useEffect, useRef } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { gsap } from 'gsap';
import logo from '../assets/ad.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  // Close menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // GSAP animation for the menu
  useEffect(() => {
    if (isOpen) {
      gsap.to(menuRef.current, {
        x: 0,
        duration: 0.5,
        ease: 'power3.out',
      });
    } else {
      gsap.to(menuRef.current, {
        x: '-100%',
        duration: 0.5,
        ease: 'power3.in',
      });
    }
  }, [isOpen]);

  return (
    <nav className="p-4 z-10 w-full">
      <div className="container mx-auto w-full flex jsutify-center  items-center  ">
        <div className="flex justify-between items-center w-full">
          <img src={logo} className="w-40 mx-auto"/>
          {/* <div className="md:hidden">
            <button ref={menuButtonRef} onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
            </button>
          </div> */}
        </div>
        {/* <div className="hidden md:flex mt-4 space-x-6 text-white">
          <a href="/" className="hover:underline">Home</a>
          <a href="/about" className="hover:underline">About</a>
          <a href="/contact" className="hover:underline">Contact</a>
        </div> */}
      </div>

      {/* Mobile Menu */}
      {/* <div
        ref={menuRef}
        className="fixed left-0 top-0 h-full w-64 bg-white shadow-md transform -translate-x-full md:hidden"
      >
        <div className="p-6 flex flex-col space-y-4">
          <a href="/" className="text-lg hover:underline">Home</a>
          <a href="/about" className="text-lg hover:underline">About</a>
          <a href="/contact" className="text-lg hover:underline">Contact</a>
        </div>
      </div> */}
    </nav>
  );
};

export default Navbar;
