import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import AddToCalendarButton from './AddToCalendarBut';
import { BiCalendarEvent } from 'react-icons/bi';
import { FaLocationArrow } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import tree from '../assets/tree3.png'
import logo from '../assets/ad.png'

const HeaderText = ({ isArabic }) => {
  const textRefs = useRef([]);

  useEffect(() => {
    // GSAP animation for staggered left-to-right fade-in
    gsap.fromTo(
      textRefs.current,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: 'power3.out',
        delay: 0.3,
        stagger: 0.3,
      }
    );
  }, []);

  useEffect(() => {
    // Animate the left tree
    gsap.to('.tree-left', {
      rotation: -5, // swinging to the left
      yoyo: true, // go back and forth
      repeat: -1, // infinite loop
      duration: 4, // duration of the swing
      ease: 'power1.inOut', // easing function to make it smooth
      transformOrigin: 'bottom center', // fixes the bottom and swings the top
    });

    // Animate the right tree
    gsap.to('.tree-right', {
      rotation: (5), // swinging to the right
      yoyo: true, // go back and forth
      repeat: -1, // infinite loop
      duration: 3, // duration of the swing
      ease: 'power1.inOut', // easing function to make it smooth
      transformOrigin: 'bottom center', // fixes the bottom and swings the top
    });
  }, []);

  return (
    <div className="w-full h-auto relative flex flex-col items-center justify-center text-center gap-8 p-4 md:p-12 pb-40 md:pt-20 z-0">
      <img src={logo} className="w-40 h-40 mx-auto my-10"/>
      <img  src={tree} className='tree-left absolute bottom-[-5%] left-[5%] w-1/3 sm:w-1/4 opacity-70'/>
      <img src={tree} className='tree-right absolute bottom-[-5%]  right-[5%] w-1/3 sm:w-1/4 opacity-70'/>
      {/* <h2
        ref={(el) => (textRefs.current[0] = el)}
        className="belleza-regular text-xl md:text-2xl pb-10"
      >
        {isArabic ? 'بقلوب مليئة بالفرح' : 'With joyful hearts'}
      </h2> */}
      <h1
        ref={(el) => (textRefs.current[0] = el)}
        className="text-base md:text-lg font-bold bg-secondary-gradient bg-clip-text text-transparent "
      >
        {isArabic
          ? 'أهلنا الغاليين'
          : 'Our dear family,'}
      </h1>
      <h1
        ref={(el) => (textRefs.current[1] = el)}
        className="text-base md:text-lg font-bold bg-secondary-gradient bg-clip-text text-transparent "
      >
        {isArabic
          ? 'يسعدنا ويشرفنا مشاركتكم فرحتنا'
          : 'we are honored and pleased to have you share in our happiness,'}
      </h1>
      <h1
        ref={(el) => (textRefs.current[2] = el)}
        className="text-base md:text-lg font-bold bg-secondary-gradient bg-clip-text text-transparent "
      >
        {isArabic
          ? 'ويسرنا خدمتكم و الاستفادة من بعض التسهيلات المقدمة'
          : 'and we are happy to offer you some special arrangements.'}
      </h1>
      <h1
        ref={(el) => (textRefs.current[3] = el)}
        className="text-base md:text-lg font-bold bg-secondary-gradient bg-clip-text text-transparent "
      >
        {isArabic ? 'دعواتكم لنا بالتوفيق' : 'Please keep us in your prayers.'}
      </h1>

      <h1
        ref={(el) => (textRefs.current[4] = el)}
        className="text-3xl md:text-4xl font-bold bg-primary-gradient bg-clip-text text-transparent "
      >
        {isArabic
          ? 'عبد العزيز خالد الشباط'
          : 'Mr. Abdulaziz Khaled Alshubat'}
      </h1>
      <h1
        ref={(el) => (textRefs.current[5] = el)}
        className="text-3xl md:text-4xl font-bold bg-primary-gradient bg-clip-text text-transparent"
      >
        {isArabic ? 'و' : '&'}
      </h1>
      <h2
        ref={(el) => (textRefs.current[6] = el)}
        className="text-3xl md:text-4xl font-bold bg-primary-gradient bg-clip-text text-transparent"
      >
        {isArabic ? 'دلال فهد السعدون' : 'Mrs. Dalal Fahad AlSaadoun'}
      </h2>


      {/* <h3
        ref={(el) => (textRefs.current[4] = el)}
        className="belleza-regular text-xl md:text-2xl py-10"
      >
        {isArabic ? 'نطلب منكم شرف الحضور' : 'Request the pleasure of your company'}
      </h3> */}

      <div className="grid grid-cols-2 py-10 z-30">
        <div ref={(el) => (textRefs.current[7] = el)} className="px-10 border-secondary border-r-[1px] flex flex-col justify-between items-center">
          <div>{isArabic ? 'قصر' : 'Mohammed Ali'}</div>
          <div>{isArabic ? ' محمد علي' : 'Palace'}</div>
          <div>{isArabic ? ' (شبرا)' : '(Shoubra)'}</div>
          <a href="https://maps.app.goo.gl/dEN63JZnGggLsN1D6" target="_blank" rel="noopener noreferrer" className='flex flex-row border-[1px] border-primary p-2 items-center gap-1 text-xs'><FaLocationDot/><div>{isArabic?'اذهب الي الموقع':'Go To Location'}</div></a>
        </div>
        <div ref={(el) => (textRefs.current[8] = el)} className="px-10 flex flex-col justify-between items-center gap-4">
          <div>{isArabic ? 'الجمعه' : 'Friday'}</div>
          <div>{isArabic ? '15 نوفمبر' : 'November 15th'}</div>
          <div>{isArabic ? '2024' : '2024'}</div>
          <a href="https://abdulazizanddalal.com/assets/abduls.ics" download="abduls.ics" className='flex flex-row border-[1px] border-primary p-2 items-center gap-1 text-xs'>
            <BiCalendarEvent/><div>{isArabic?'اضف للتقويم':'Add to calendar'}</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderText;
