import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import bg19 from '../assets/bg-19.jpg';

const Footer = ({ isArabic }) => {
  const countdownRefs = useRef([]);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Target event date (15th of November, 2024)
  const targetDate = new Date('November 15, 2024 20:00:00').getTime();

  useEffect(() => {
    // GSAP animation for countdown numbers
    gsap.fromTo(
      countdownRefs.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: 'power3.out',
        delay: 0.5,
        stagger: 0.4,
      }
    );

    // Countdown timer
    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(countdown);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <div
      
      className={`w-full text-primary relative h-[50vh]  md:pt-0 text-center flex flex-col justify-center items-center  ${isArabic ? 'rtl' : ''}`}
    >
      {/* Countdown Section */}
      <div
        ref={(el) => (countdownRefs.current[0] = el)}
        className={`text-4xl md:text-6xl text-primary font-bold flex flex-row flex-wrap text-center justify-evenly items-center gap-2 md:gap-8 w-full ${isArabic ? 'flex-row-reverse' : ''}`}
      >
        <div className="flex flex-col items-center">
          <span>{timeLeft.days}</span>
          <span className="text-sm md:text-xl">{isArabic ? 'أيام' : 'Days'}</span>
        </div>
        <span className="text-2xl md:text-6xl font-bold">:</span>
        <div
          ref={(el) => (countdownRefs.current[1] = el)}
          className="flex flex-col items-center"
        >
          <span>{timeLeft.hours}</span>
          <span className="text-sm md:text-xl">{isArabic ? 'ساعات' : 'Hours'}</span>
        </div>
        <span className="text-2xl md:text-6xl font-bold">:</span>
        <div
          ref={(el) => (countdownRefs.current[2] = el)}
          className="flex flex-col items-center"
        >
          <span>{timeLeft.minutes}</span>
          <span className="text-sm md:text-xl">{isArabic ? 'دقائق' : 'Minutes'}</span>
        </div>
        <span className="text-2xl md:text-6xl font-bold">:</span>
        <div
          ref={(el) => (countdownRefs.current[3] = el)}
          className="flex flex-col items-center"
        >
          <span>{timeLeft.seconds}</span>
          <span className="text-sm md:text-xl">{isArabic ? 'ثواني' : 'Seconds'}</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
