import React, { useState } from 'react';
import Navbar from './components/Navbar';
import HeaderSlider from './components/HeaderSlider';
import HeaderText from './components/HeaderText';
import Footer from './components/Footer';
import TwoSideSection from './components/TwoSideSection';
import bg16 from './assets/bg-16.jpg'
import bg1 from './assets/paperbg1.jpg'

function App() {
  const [isArabic, setIsArabic] = useState(null); // Initialize as null to show the language selection screen
  const [languageSelected, setLanguageSelected] = useState(false); // Track if the language is selected

  const handleLanguageSelection = (isArabic) => {
    setIsArabic(isArabic); // Set Arabic or English based on user selection
    setLanguageSelected(true); // Set the flag to true to show the rest of the website
  };

  // Show the language selection page first
  if (!languageSelected) {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center bg-black text-center text-primary belleza-regular" style={{
        backgroundImage: `url(${bg1})`,
        backgroundSize: 'fit',
        backgroundPosition: 'center',
      }}>
        <h1 className="text-4xl mb-8">Choose Your Language</h1>
        <div className="flex gap-8">
          <button
            onClick={() => handleLanguageSelection(false)} // English
            className="px-6 py-3 bg-white text-black rounded-lg"
          >
            English
          </button>
          <button
            onClick={() => handleLanguageSelection(true)} // Arabic
            className="px-6 py-3 bg-white text-black rounded-lg"
          >
            العربية
          </button>
        </div>
      </div>
    );
  }

  // Once language is selected, show the rest of the website
  return (
    <div className={`text-primary ${isArabic?'tajawal-medium':'cinzel-decorative-bold'} overflow-hidden w-full h-full overflow-y-scrol  z-[10] relative ${isArabic ? 'rtl' : ''}`} >
      <div className='w-full h-screen overflow-y-scroll relative ' style={{
                                                  backgroundImage: `url(${bg1})`,
                                                  backgroundSize: 'fit',
                                                  backgroundPosition: 'center',
                                                }}>
        {/* <HeaderSlider /> */}
        {/* <Navbar /> */}
        <HeaderText isArabic={isArabic} />
      <TwoSideSection isArabic={isArabic} />
      <Footer isArabic={isArabic} />
      <div className='w-full h-auto bg-black text-center  text-white py-10'> Powered by <a target="_blank" rel="noopener noreferrer" href={isArabic?'https://flokisoft.com/products?lang=ar&id=1':'https://flokisoft.com/products?lang=en&id=1'} className='text-secondary font-bold underline' >FlokiEvents</a> </div>
      </div>
    </div>
  );
}

export default App;
